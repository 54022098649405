import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';

const CapabilityBody = styled.section`
  ${tw`
    py-1
    md:py-4
    lg:py-8
  `};
  h1 {
    ${tw`
      font-light
      text-xl
      xxs:text-2xl
      xs:text-3xl
      sm:text-4xl
      md:text-h2
      lg:text-h1
      leading-h1-loose
    `};
  }
  p {
    ${tw`
      md:font-light
      md:text-lg
      lg:text-xl
      xl:text-xxl
      xl:tracking-tight
    `}
  }
`;
const ColText = styled.div`
  ${tw`
    w-full
    md:pr-2
    md:w-1/2
    lg:pr-1/8
  `};
`;
const ColAreas = styled.div`
  ${tw`
    w-full
    md:pr-2
    lg:pr-3
    md:w-1/2
  `};
`;

const AreasLabel = styled.h2`
  ${tw`
    pt-1
    md:pt-0
    font-regular
    md:font-light
    text-xl
    md:text-2xl
    xl:text-3xl
  `};
  &:after {
    content: "";
    ${tw`
      block
      bg-gray-300
      my-1.5
      md:my-3
      w-4/5
      h-2px
    `};
  }
`;
const AreasList = styled.ul`
    ${tw`
      list-none
      pb-3
    `};
  `;
const AreasListItem = styled.li`
  ${tw`
    font-regular
    font-serif
    xl:text-2xl
    pl-2.5
    mb-1.5
    relative
  `};
  &:before {
    content: "";
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    ${tw`
      block
      border-solid
      border-brand-primary
      border-3
      bg-white
      w-12px
      h-12px
      mt-2px
      absolute
    `};
  }
`;

const ColImg = styled.div`
  ${tw`
    w-full
    min-h-12
    mb-3
    md:text-right
    pr-0.5
    sm:pr-1
    md:pr-2
    lg:pr-2
    xl:pl-3
    xl:pr-1/12
    md:w-11/12
    lg:w-2/5
    xl:w-7/12
    self-stretch
  `};
  div {
    ${tw`h-full flex`}
    img {
      width: 100%;
    }
  }
`;

const animationDuration = 600;

export default class Component extends React.Component {
  render () {
    const {
      content,
      serviceAreas
    } = this.props;
    logger.debug(`content`, content)
    if (!content) {
      return (
        <div>
          Loading...
        </div>
      )
    }
    return (
      <CapabilityBody>
        <Container>
          <Row>
            <ColText>
              <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <MDXRenderer>
                  {content.childMdx.body}
                </MDXRenderer>
              </Fade>
            </ColText>
            <ColAreas>
              <AreasLabel>
                Business Functions
              </AreasLabel>
              <AreasList>
                {serviceAreas && serviceAreas.map((area) => {
                  const {
                    id,
                    title
                  } = area;
                  return (
                    <AreasListItem key={id}>
                      {title}
                    </AreasListItem>
                  )
                })}
              </AreasList>
            </ColAreas>
            {/*
            <ColImg>
              <Fade right duration={animationDuration} distance={`0%`} delay={animationDuration}>
                <div>
                  <img src="https://www.prisma.io/static/illustrations/cloud.svg" alt="" />
                </div>
              </Fade>
            </ColImg>
            */}
          </Row>
        </Container>
      </CapabilityBody>
    );
  }
}
Component.propTypes = {
  content: PropTypes.string.isRequired
};
Component.defaultProps = {
};
