import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';

const VideoBackground = styled.div`
  ${tw`
    absolute
  `}
  left: 0; right: 0;
  top: 0; bottom: 0;
  :after {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(0,0,0,.80);
  }
`;
const Cover = styled.section`
  ${tw`
    fixed
    z--1
    min-h-screen
    w-full
    flex
    mb-0
    top-0
    items-stretch
    content-center
    text-left
    lg:text-center
    flex-wrap
  `};
  video {
    ${tw`
      absolute
    `};
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }
  ${(props) => {
    const { background } = props;
    const bgColor = props.bgColor ? props.bgColor : `bg-gray-200`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      !isLight ? tw`text-white` : tw`text-black`,
      backgroundColor && `background-color: ${backgroundColor};`,
      background && (background.image && `background-image: url('${background.image.substring(0, background.image.indexOf('?'))}?w=1200&q=90');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`),
      background && (`
        :before {
          content: "";
          position: absolute;
          left: 0; right: 0;
          top: 0; bottom: 0;
          background: rgba(0,0,0,.5);
        }`)
    ];
  }};
`;
const Content = styled.div`
  ${tw`
    flex-2
    mx-auto
    px-1/24
    md:px-0
    max-w-full
  `};
  min-height: 430px;
`;

const Red = styled.span`
  ${tw`
    inline-block
  `};
`;

const Title = styled.h1`
  ${tw`
    mx-auto
    font-thin
    font-serif
    tracking-tight
    text-4xl
    leading-tighter
    md:text-hero
    lg:leading-hero-xl-loose
    lg:text-hero-xl
    xl:max-w-5/6
    xl:text-superhero
  `};
`;

const Text = styled.div`
  ${tw`
    mb-0
    mx-auto
    tracking-wide
    text-md
    md:text-lg
    max-w-full
    sm:max-w-11/12
    md:max-w-3/4
    lg:max-w-2/3
    xl:max-w-1/2
  `};
  ${(props) => {
    const bgColorProcessed = (props.bgColor.startsWith('#') || props.bgColor.startsWith('rgb') || props.bgColor.startsWith('hsl')) ? props.bgColor : colors[props.bgColor];
    const color = Color(bgColorProcessed).isLight() ? `light` : `dark`;
    return [
      // props.bgColor && `background-color: ${bgColorProcessed};`,
      color === `dark` ? tw`text-gray-200` : tw`text-gray-700`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const {
      label,
      content,
      intro,
      bgColor,
      background
    } = this.props;
    const animationDuration = 600;
    return (
      <Cover {...this.props}>
        {background && (
          background.video && (
            <VideoBackground>
              <video autoPlay loop muted ref={(player) => { this.player = player }}>
                <source src={background.video} type="video/mp4" />
              </video>
            </VideoBackground>
          )
        )}
        <Container>
          <Row>
            <Content>
              <Fade top duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <div>
                  <Title>
                    {intro}
                  </Title>
                </div>
              </Fade>
              <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <Text bgColor={bgColor}>
                  <MDXProvider
                    components={{
                      Red: Red
                    }}
                  >
                    <MDXRenderer>
                      {content}
                    </MDXRenderer>
                  </MDXProvider>
                </Text>
              </Fade>
            </Content>
          </Row>
        </Container>
      </Cover>
    );
  }
}
Component.propTypes = {
  intro: PropTypes.string,
  label: PropTypes.string,
  bgColor: PropTypes.string,
  content: PropTypes.string
};
Component.defaultProps = {
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  content: 'We tailor our services to meet the needs of each client, and work together every step of the way to bring excellent products, services and experiences to market. We ground our work in market opportunity, stay relentlessly focused on outcomes and help our clients grow internal capabilities. Whatever it takes, frog makes it real.',
  label: 'Intro',
  bgColor: 'white'
};
