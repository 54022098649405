import React from 'react';
import { connect } from 'react-redux';
// import {bindActionCreators} from 'redux';

import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import {
  // StaticQuery,
  // graphql,
  navigate,
  Link
} from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Cookies from 'js-cookie';
import Auth from 'modules/Auth0/Auth';
import logger from 'utils/logger';

import { ReactComponent as THINKLO } from 'assets/THINKLO-logo-light.svg';
import { navigateRedux } from 'modules/Redux/actions/navigate';

const auth = new Auth();

const easing = 500;

const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
`;

const Menu = styled.main`
  ${tw`
    block
    h-full
    left-0
    overflow-hidden
    truncate
    flex
    text-white
    flex-row
    fixed
    top-0
    left-0
    w-screen
    h-screen
    z-40
  `};
  ${transition}
  ${({ consentStyleBox, displayConsent }) => {
    return [
      displayConsent && !consentStyleBox && tw`pt-6`
    ];
  }};
  ${({ menu }) => {
    return [
      menu ? tw`pointer-events-auto` : tw`pointer-events-none`
    ];
  }};
`;
const MenuMain = styled.main`
  ${tw`
    block
    h-full
    left-0
    overflow-hidden
    truncate
  `};
  border-right: 1px solid rgba(250, 250, 250, 0.15);
  transition: all ${easing * 1.5}ms cubic-bezier(.1,.55,.4,1);
  ${({ menu }) => {
    return [
      !menu ? `transition-delay: ${easing * 0.5}ms;` : `transition-delay: 0;`,
      menu ? `background-color: rgba(0,0,0,0.95);` : `background-color: rgba(0,0,0,0.3); border-color: transparent;`,
      menu ? tw`w-full lg:w-2/3 xl:w-7/12` : tw`w-0`
    ];
  }};
`;
const MenuSecondary = styled.aside`
  ${tw`
    flex-row
    h-full
    lg:w-1/3
    xl:w-5/12
    absolute
    top-0
    right-0
    h-4/5
  `};
`;
const MenuTertiary = styled.aside`
  ${tw`
    block
    lg:w-1/3
    xl:w-5/12
    absolute
    bg-gray-300
    truncate
    right-0
    z-10
    m-0
    text-center pt-3
  `};  
  border-right: 1px solid rgba(250, 250, 250, 0.15);
  top: 80%;
  ${transition}
  ${({ menu }) => {
    return [
      !menu ? `transition-delay: ${easing * 0.5}ms;` : `transition-delay: 0;`,
      menu ? `background-color: rgba(0,0,0,0.95);` : `background-color: rgba(0,0,0,0.3);`,
      menu ? tw`opacity-100 h-1/5` : tw`opacity-0 h-0`
    ];
  }};
`;
const MenuBlock = styled.div`
  ${tw`
    block
    xl:absolute
    overflow-hidden
    text-center pt-3
    truncate
  `};
  ${transition}
  ${({ menu }) => {
    return [
      !menu ? `transition-delay: ${easing * 0.5}ms;` : `transition-delay: 0;`,
      menu ? `background-color: rgba(0,0,0,0.95);` : `background-color: rgba(0,0,0,0.3);`
    ];
  }};
  &:nth-child(1) {
    border-bottom: 1px solid rgba(250, 250, 250, 0.15);
    border-right: 1px solid rgba(250, 250, 250, 0.15);
    ${tw`lg:h-1/4 w-full xl:h-1/2 xl:top-0 xl:left-0`};
    ${({ menu }) => { return [menu ? tw`xl:w-1/2 opacity-100` : tw`xl:w-0 opacity-0`]; }};
  }
  &:nth-child(2) {
    border-bottom: 1px solid rgba(250, 250, 250, 0.15);
    border-right: 1px solid rgba(250, 250, 250, 0.15);
    ${tw`lg:h-1/4 w-full xl:w-1/2 xl:right-0 xl:top-0`};
    ${({ menu }) => { return [menu ? tw`xl:h-1/2 opacity-100` : tw`xl:h-1 opacity-0`]; }};
    
  }
  &:nth-child(3) {
    border-bottom: 1px solid rgba(250, 250, 250, 0.15);
    border-right: 1px solid rgba(250, 250, 250, 0.15);
    ${tw`lg:h-1/4 w-full xl:w-1/2 xl:left-0 xl:bottom-0`};
    ${({ menu }) => { return [menu ? tw`xl:h-1/2 opacity-100` : tw`xl:h-1 opacity-0`]; }};
  }
  &:nth-child(4) {
    border-bottom: 1px solid rgba(250, 250, 250, 0.15);
    border-right: 1px solid rgba(250, 250, 250, 0.15);
    ${tw`lg:h-1/4 w-full xl:w-0 xl:right-0 xl:h-1/2`};
    top: 50%;
    ${({ menu }) => { return [menu ? tw`xl:w-1/2 opacity-100` : tw`xl:w-0 opacity-0`]; }};
  }
`;
const MenuList = styled.ul`
  ${tw`
    m-0
    list-reset
    left-0
    px-1/12
    lg:pl-10
    absolute
  `};
  top: 50%;
  transform: translateY(-50%);
`;
const MenuItem = styled.li`
  ${tw`
    font-light
    relative
    text-3xl
    lg:text-hero-xl
    lg:leading-hero-xl
    xl:text-superhero
    xl:leading-superhero
    mb-2
    lg:mb-4
    inline-block
    text-gray-200
    cursor-pointer
    tracking-tight
  `};
  ${transition}
  &::after {
    ${tw`block absolute bg-brand-primary w-0 mt-2`};
    content: '';
    height: 2px;
    ${transition}
  }
  &:hover,
  &:focus {
    ${tw`text-white tracking-normal`};
    &::after {
      ${tw`w-full`};
    }
  }
  &::before {
    ${tw`text-sm tracking-normal font-bold block pt-0.5 text-gray-600 absolute top-0`};
    left: -3rem;
  };
  ${({ index }) => {
    return [
      `&::before {
        content: '0${index + 1}.'
      };`
    ];
  }};
`;
const LogoNav = styled.div`
  ${tw`
    fixed
    block
    cursor-pointer
    z-50
    lg:mr-0.5
    xl:mr-1
    bg-black
  `};
  ${transition}
  top: 1.5rem;
  left: 1.5rem;
  height: 45px;
  width: 45px;
  &:before,
  &:after {
    ${tw`absolute block z-10 pointer-events-none`};
    content: " ";
    width: 19px;
    height: 6px;
    left: 13px;
    transform: rotate(0);

    ${({ menu }) => { menu ? tw`pointer-events-auto` : tw`pointer-events-none`; }};

    ${transition}
  }
  & span {
    ${tw`
      absolute
      pointer-events-none
      block
      bg-transparent
      select-none
      left-0
    `};
    height: 45px;
    width: 45px;
    &:before {
      ${tw`absolute block top-0 left-0 w-full h-full`};
      content: " ";
    }
    ${transition}
    svg {
      ${tw`relative opacity-0`};
      ${transition}
      top: 12px;
      height: 22px;
      fill: white;
      left: 13px;
      & path {
        transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1) 0ms;
      }
    }
    &:hover {
      ${tw`bg-black`};
      svg {
        left: ${45}px;
        & path:nth-child(1) {
          transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1) ${easing / 4}ms;
          opacity: 1 !important;
        }
      }
    }
  }
  &:before {
    top: 13px;
  }
  &:after {
    bottom: 19px;
    transform: rotate(-90deg);
  }
  &:focus,
  &:hover{
    ${tw`bg-brand-primary`};
    outline: 0 !important;
    &:before,
    &:after {
      ${tw`bg-white`};
      height: 3px;
    }
    &:before {
      top: 16px;
    }
    &:after {
      bottom: 16px;
    }
  }
  ${({ menu, colorScheme, showLogo }) => {
    return [
      `&:hover::after, &:focus::after {
        transform: ${menu ? `rotate(-45deg)` : `rotate(0deg)`};
      }`,
      colorScheme === `dark` ? `
        &:before,
        &:after {
          background-color: ${menu ? `white` : `black`};
        }
      ` : `
        &:before,
        &:after {
          background-color: white;
        }
      `,
      showLogo && `& span {
        svg {
          opacity: 1;
          & path:nth-child(1) {
            opacity: 0;
          }
        }
      }`,
      menu && `
      border-radius: 4px;
      span {
        width: 156px;
        left: 5rem;
        svg {
          opacity: 1;
        }
        &:hover,
        &:focus {
          background-image: url('https://media.giphy.com/media/fA6opG8tgo2KQ/giphy.gif');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      &:before,
      &:after {
        width: 2rem;
        height: 3px;
        left: 7px;
      }
      &:before {
        transform: rotate(45deg);
        top: 21px !important;
      }
      &:after {
        transform: rotate(-45deg);
        bottom: 21px !important;
      }
    `];
  }};
`;

class Component extends React.Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (to) {
    navigateRedux(to);
  }

  render () {
    const CONSENT = Cookies.get('CONSENT');
    const consentStyleBox = process.env.GATSBY_CONSENT_BOX === `TRUE` && true;
    const displayConsent = !CONSENT || CONSENT < 1 && true;

    const { menu, toggleMenu, closeMenu, /*navigateRedux*/ } = this.props;
    const { isAuthenticated } = auth;
    const user = auth.getUser();

    logger.verbose(`isAuthenticated: ${isAuthenticated}`, `user`, user);

    const mainMenu = [
      {
        id: `1`,
        slug: `what-we-do`,
        label: `What We Do`
      },
      // {
      //   id: `2`,
      //   slug: `approach`,
      //   label: `Approach`
      // },
      // {
      //   id: `3`,
      //   slug: `our-work`,
      //   label: `Our Work`
      // },
      {
        id: `4`,
        slug: `about-us`,
        label: `About Us`
      },
      {
        id: `5`,
        slug: `contact`,
        label: `Contact`
      }
    ];
    return (
      <div>
        <Menu menu={menu} displayConsent={displayConsent} consentStyleBox={consentStyleBox}>
          <MenuMain menu={menu}>
            <MenuList>
              { mainMenu.map((navItem, i) => {
                const navItemProps = {
                  index: i,
                  /*onClick: (navigateRedux) => {
                    navigateRedux;
                    navigate(
                      `/${navItem.slug}`,
                      // navigateRedux
                    );
                  },*/
                  onClick: () => {
                    this.handleClick(`/${navItem.slug}`);
                    closeMenu();
                  },
                  role: `link`
                };
                return (
                  <Fade
                    key={`Fade${navItem.id}`}
                    top
                    when={menu}
                    duration={easing}
                    distance={`20%`}
                    delay={menu ? 500 + (i * 50) : (i * 20)}
                  >

                    <MenuItem key={navItem.id} {...navItemProps}>
                      {navItem.label}
                    </MenuItem>
                  </Fade>
                );
              })}
            </MenuList>
          </MenuMain>
          <MenuSecondary>
            <MenuBlock menu={menu}>
              {/*
              The <b>TANK</b>
              <br />
              {!user && (
                <a
                  onClick={() => auth.login()}
                  role="none"
                  className="nav-link"
                >
                  Log In
                </a>
              )}
              {user && (
                <Link className="nav-link" role="none" to={`/account/profile`}>
                  {
                    user && (
                      <span className={`avatar-container`}>
                        <span className="avatar avatar-32 mr-5em">
                          <img src={user.picture} alt={`${user.given_name}'s avatar`} />
                        </span>
                        {user.given_name}
                      </span>
                    )
                  }
                </Link>
              )}
              {user && (
                <a
                  onClick={() => auth.logout()}
                  role="none"
                  className="nav-link"
                >
                  Log Out
                </a>
              )}
              */}
            </MenuBlock>
            <MenuBlock menu={menu}>
              {/*Decks*/}
            </MenuBlock>
            <MenuBlock menu={menu}>
              {/*Cases*/}
            </MenuBlock>
            <MenuBlock menu={menu}>
              {/*Social & Login*/}
            </MenuBlock>
          </MenuSecondary>
          <MenuTertiary menu={menu}>
            {/*Subscribe*/}
          </MenuTertiary>
        </Menu>
        <LogoNav
          onClick={toggleMenu}
          menu={menu}
          displayConsent={displayConsent}
          consentStyleBox={consentStyleBox}
        >
          <span>
            <THINKLO
              onClick={() => navigate(`/${`landing`}`)}
              role={`link`}
            />
          </span>
        </LogoNav>
      </div>
    );
  }
}

Component.propTypes = {
  menu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  navigateRedux: PropTypes.func.isRequired
};

const mapStateToProps = ({ menu }) => {
  return { menu };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleMenu: () => dispatch({ type: `TOGGLE_MENU` }),
    // closeMenu: () => dispatch({ type: `CLOSE_MENU`, payload: ownProps }),
    closeMenu: () => dispatch({ type: `CLOSE_MENU` }),
    navigateRedux: () => dispatch(navigateRedux(ownProps))

  };
  // bindActionCreators({
  //   // getWantedList: getWantedList,
  //   navigateRedux: navigateRedux
  // }, dispatch);
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

class ReduxComponent extends React.Component {
  render () {
    return (
      <ConnectedComponent />
    );
  }
}

export default ReduxComponent;

// export function navigateRedux (value) {
//   return {
//     type: `CLOSE_MENU`,
//     value // it will add key `value` with argument value. 
//   };
// }

