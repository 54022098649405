import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

const Text = styled.h4`
  ${tw`
    font-sans
    leading-normal
    mb-1
    tracking-megawide
    font-medium
    uppercase
  `};
  font-size: 16px;
  em {
    ${tw`
      mr-1.5
      not-italic
      tracking-wide
      normal-case
    `};
  }
`;

export default class Component extends React.Component {
  render () {
    const { label, ordinal } = this.props;
    return (
      <Text>
        {ordinal && <em>{ordinal}.</em>}
        {label}
      </Text>
    );
  }
}

Component.propTypes = {
  label: PropTypes.string,
  ordinal: PropTypes.string
};
Component.defaultProps = {
  label: ``,
  ordinal: ``
};
