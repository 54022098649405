import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Screens from 'styles/Screens';
import PropTypes from 'prop-types';
// self-center
const Container = styled.div`
  ${tw`
    flex-2
    w-full
    mx-auto
    ml-0
  `};
  ${({ align }) => {
    return [
      !align && tw`self-auto`,
      (align === `center`) && tw`self-center`,
      (align === `start` || align === `top`) && tw`self-start`,
      (align === `end` || align === `bottom`) && tw`self-end`
      // align === `strech` && tw`self-strech`
    ];
  }};
  ${({ fluid }) => {
    return !fluid && [
      tw`
        px-2
        xxs:px-1
        pl-0
        sm:pl-1/12
        md:pl-1/12
      `,
      `@media only screen and (min-width: ${Screens.xs}){max-width: ${Screens.xs};}`,
      `@media only screen and (min-width: ${Screens.sm}){max-width: ${Screens.sm};}`,
      `@media only screen and (min-width: ${Screens.md}){max-width: ${Screens.md};}`,
      `@media only screen and (min-width: ${Screens.lg}){max-width: ${Screens.lg};}`,
      `@media only screen and (min-width: ${Screens.xl}){max-width: ${Screens.xl};}`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const { props } = this;
    const { children } = this.props;
    return (
      <Container {...props}>
        {children}
      </Container>
    );
  }
}
Component.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.bool
};
Component.defaultProps = {
  children: ``,
  fluid: false
};
