import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import ProximityFeedback from 'react-proximity-feedback';
import detectIt from 'detect-it';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import { Tween, Controls, Timeline } from 'react-gsap';


import logger from 'utils/logger';
import {
  Container
} from 'components/GridSystem';
import {
  PrettyTitle,
  SectionLabel
} from 'components/Text';

import { ReactComponent as LogoTHINK } from 'assets/THINK-logo.svg';
import { ReactComponent as LogoLO } from 'assets/LO-logo.svg';

logger.debug(`deviceType`, detectIt.deviceType);

const modifier = 1.25;
const topAdjust = 2.7;
const logoHeight = 72 * modifier;
const logoWidthTHINK = 299 * modifier;
const logoWidthLO = 123 * modifier;
const easing = 500;

const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;

const stylesLogo = css`
  ${tw`
    block
    absolute
    m-0
    text-superhero
    font-black
    cursor-pointer
    text-gray-800
    tracking-tighter
    fill-current stroke-current
  `};
  top: -${topAdjust}rem;
  svg {
    ${tw`fill-current stroke-current relative m-0`}
    heigh: ${logoHeight}px;
  }
  &:hover{
    ${tw`
      text-black
    `};
  }
  transform: translateX(-0%);
  ${transition}
`;

const verticalCenter = css`
  ${tw`
    relative
  `};
  top: 50%;
  transform: translateY(-50%);
`;

const Section = styled.article`
  ${tw`
    relative
    min-h-screen
    flex
    mb-0
    items-stretch
    content-center flex-wrap
  `};
  &:before {
    ${tw`absolute opacity-80 top-0 left-0 bottom-0 right-0`}
    content: "";
    ${({ background, color }) => { return background && [
            `background-color: ${color || `transparent`};`,
            background.image && `background-image: url('${background.image}');`,
            background.size ? `background-size: ${background.size};` : `background-size: cover;`,
            background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`,
            background.position ? `background-position: ${background.position};` : `background-position: center;`
          ];
    }};
  }
`;

const VerticalCenter = styled.div`
  ${verticalCenter}
  ${tw`
    absolute
    left-0
    w-full
  `};
`;

const ShotCalling = styled.div`
  ${tw`
    text-sm
    cursor-pointer
    font-normal
    tracking-normal
    text-center
    relative
    py-3
  `};
  ${(props) => {
    const { active } = props;
    return [
      active ? tw`opacity-100` : tw`opacity-0`,
      !active ? tw`pointer-events-none` : tw`pointer-events-auto`
    ];
  }}
  ${transition}
`;

const Content = styled.div`
  ${tw`
    flex-2 
    w-full
  `};
`;

const Logo = styled.div`
  ${tw`
    flex
    w-full
    h-1
    text-center
    m-auto
    relative
  `};
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  ${({ state, distance, startAt }) => {
    return [
      `transform: scale3D(${state.showTHINK || state.showLO || distance < startAt ? `1,1,1` : `1.5,1.5,1`});`
    ];
  }}
`;

const LogoLarge = styled.div`
  ${tw`
    invisible
    md:visible
  `};
`;

const LogoFragment = styled.div`
  ${tw`
    w-1/2
    relative
  `};
`;

const THINK = styled.div`
  ${tw`
  `};
  svg {
    width: ${logoWidthTHINK}px;
  }
  ${(props) => {
    const {
      move,
      active,
      lockAt,
      treshold,
      startAt,
      distance,
      centerAdjustment,
      state
    } = props;

    const maxMove = 50;
    let right = maxMove;
    const moveAmmount = right + treshold - move;

    let svgLeft = (logoWidthTHINK / 2) - 8;

    if (active || state.showTHINK || state.showLO) {
      right = maxMove;
    } else if (distance < startAt) {
      // right = (distance > lockAt) ? moveAmmount : maxMove;
      right = maxMove;
    } else {
      right = -centerAdjustment;
      svgLeft = 0;
    }

    return [
      state.showLO ? tw`opacity-0` : tw`opacity-100`,
      state.showLO || right !== maxMove ? tw`pointer-events-none` : tw`pointer-events-auto`,
      `right: ${right}%;`,
      `svg {
        left: ${svgLeft}px;
      };`
    ];
  }}
  ${stylesLogo}
`;

const LO = styled.div`
  svg {
    width: ${logoWidthLO}px;
  }
  ${(props) => {
    const {
      move,
      active,
      lockAt,
      treshold,
      startAt,
      distance,
      centerAdjustment,
      state
    } = props;

    const maxMove = 50;
    let left = maxMove;
    const moveAmmount = left + treshold - move;

    let svgLeft = (logoWidthLO / 2) - 8;

    if (active || state.showTHINK || state.showLO) {
      left = maxMove;
    } else if (distance < startAt) {
      // left = (distance > lockAt) ? moveAmmount : maxMove;
      left = maxMove;
    } else {
      left = centerAdjustment + 0.5;
      svgLeft = 0;
    }

    return [
      state.showTHINK ? tw`opacity-0` : tw`opacity-100`,
      state.showTHINK || left !== maxMove ? tw`pointer-events-none` : tw`pointer-events-auto`,
      `left: ${left}%;`,
      `svg {
        left: -${svgLeft}px;
      };`
    ];
  }}
  ${stylesLogo}
`;

const Ref = styled.div`
  ${tw`
    absolute
    block
    py-1
    px-1
    p-l
    h-1
    invisible
  `};
  width: 75vw;
  top: 50%;
  transform: translateY(-50%);
`;

const Status = styled.div`
  ${tw`
    fixed
    top-0
    right-0
    block
    px-0.5
    py-0.25
    leading-none
    bg-white
  `};
  font-size: 10px;
`;

const Cross = styled.span`
  ${tw`
    absolute
    overflow-hidden
    opacity-85
    rounded-full
    pointer-events-none
  `};
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
  &:before, 
  &:after {
    ${tw`
      absolute
      bg-black
    `};
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    content: ' ';
    height: 100px;
    width: 2px;
    ${transition}
  }
  ${(props) => {
    const { startAt, distance, state } = props;
    const activeSlide = state.showTHINK || state.showLO;
    const crossSizeBase = distance < startAt ? logoHeight * 0.75 : 0;
    const crossSize = distance < 90 ? logoHeight : crossSizeBase;
    return [
      `width: ${!activeSlide ? crossSize : 0}px;`,
      `height: ${!activeSlide ? crossSize : 0}px;`,
      `&:before {
        transform: translate3D(-50%, -50%, 0) rotate(${distance < 90 ? 45 : 90}deg);
      }`,
      `&:after {
        transform: translate3D(-50%, -50%, 0) rotate(${distance < 90 ? -45 : 0}deg);
      }`
    ];
  }}
  ${transition}
`;

const Col = styled.div`
  ${tw`
    w-1/2
    h-screen
    text-center
    bg-brand-secondary-800
    relative
  `};
  &:before {
    ${tw`
      absolute
      top-0
      left-0
      h-full
      w-full
    `};
    content: " ";
    ${transition}
  }
  ${(props) => {
    const {
      active,
      state,
      target,
      bgImage
    } = props;
    // marvel https://media.giphy.com/media/fA6opG8tgo2KQ/giphy.gif
    const otherSide = target === `THINK` ? `LO` : `THINK`;
    return [
      state.showTHINK || state.showLO ? tw`pointer-events-none` : tw`pointer-events-auto`,
      active ? tw`opacity-100` : tw`opacity-0`,
      `&:before {
        opacity: ${active || state[target] ? `1` : `0`};
        background-image: url('${bgImage}');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      };`
    ];
  }}
  ${transition}
`;

const Slides = styled.div`
  ${tw`
    absolute
    w-full
    top-0
  `};
  ${(props) => {
    const { active } = props;
    // marvel https://media.giphy.com/media/fA6opG8tgo2KQ/giphy.gif
    return [
      active ? tw`pointer-events-auto` : tw`pointer-events-none`
    ];
  }}
`;
const SlideTHINK = styled.div`
  ${tw`
    absolute
    overflow-hidden
  `};
  left: 50%;
  ${(props) => {
    const { active } = props;
    // marvel https://media.giphy.com/media/fA6opG8tgo2KQ/giphy.gif
    return [
      active ? tw`pointer-events-auto` : tw`pointer-events-none`
    ];
  }}
`;
const SlideLO = styled.div`
  ${tw`
    absolute
    overflow-hidden
  `};
  left: 0%;
  width: 50%;
  ${(props) => {
    const { active } = props;
    // marvel https://media.giphy.com/media/fA6opG8tgo2KQ/giphy.gif
    return [
      active ? tw`pointer-events-auto` : tw`pointer-events-none`
    ];
  }}
`;

const MoreInfo = styled.div`
  ${tw`
    w-screen
    h-screen
    text-left
    flex
  `};
`;

const MoreInfoImage = styled.div`
  ${tw`
    w-1/2
    h-full
    relative
    bg-white
  `};
`;
const MoreInfoContainer = styled.div`
  ${tw`
    w-1/2
    h-full
    relative
    bg-black
    text-white
    px-5
  `};
`;
const MoreInfoContent = styled.div`
  transition-delay: 5s;
  ${verticalCenter}
  ${transition}
  ${(props) => {
    const { active } = props;
    return [
      active ? tw`opacity-100` : tw`opacity-0`
    ];
  }}
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      THINK: false,
      LO: false,
      showTHINK: false,
      showLO: false
    };
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleToggleSlide = this.handleToggleSlide.bind(this);
  }

  handleMouseHover (target, interaction) {
    logger.info('LEAVE target', target);
    const stateUpdate = interaction === `enter` && true;
    this.setState({
      [target]: stateUpdate
    });
  }

  handleToggleSlide (target) {
    logger.debug(`handleToggleSlide (target)`, target);
    const { showTHINK, showLO } = this.state;
    if (target === `THINK`) {
      this.setState({ showTHINK: !showTHINK });
    }
    if (target === `LO`) {
      this.setState({ showLO: !showLO });
    }
  }

  render () {
    const { state } = this;
    logger.verbose(`state`, state);
    const {
      treshold,
      lockAt,
      startAt,
      centerAdjustment
    } = this.props;

    return (
      <ProximityFeedback>
        {({ ref, distance }) => {
          const proximity = Math.ceil((distance) / treshold) * treshold;
          const move = (proximity / treshold) + treshold;
          const childrenProps = {
            distance: proximity,
            move,
            lockAt,
            startAt,
            treshold,
            centerAdjustment,
            state
          };

          return (
            <Section background={{
              imageX: `https://cdn.dribbble.com/users/588022/screenshots/1780726/velocity2light.gif`
            }}>
              <Slides>
                <SlideTHINK active={this.state.showTHINK}>
                  <Slide left when={this.state.showTHINK} duration={800} unmountOnExit>
                    <MoreInfo>
                      <MoreInfoContainer>
                        <MoreInfoContent active={this.state.showTHINK}>
                          <SectionLabel label={`Focus`} />
                          <Fade left when={this.state.showTHINK}>
                            <PrettyTitle>Some intro <i>about</i> <b>strategy.</b></PrettyTitle>
                          </Fade>
                          <Fade left cascade when={this.state.showTHINK} duration={500}>
                            <div>
                              <p>
                                We are an innovation consultancy creating new products, services and experiences that deliver sustainable, profitable growth for the world’s most ambitious companies
                              </p>

                              <a href="">What We Do</a>
                              <a onClick={e => this.handleToggleSlide(`THINK`)}>Back</a>
                            </div>
                          </Fade>
                        </MoreInfoContent>
                      </MoreInfoContainer>
                    </MoreInfo>
                  </Slide>
                </SlideTHINK>
                <SlideLO active={this.state.showLO}>
                  <Slide right when={this.state.showLO} duration={800} unmountOnExit>
                    <MoreInfo>
                      <MoreInfoContainer>
                        <MoreInfoContent active={this.state.showLO}>
                          <Fade right when={this.state.showLO}>
                            <h1 onClick={e => this.handleToggleSlide(`LO`)}>React Reveal LO</h1>
                          </Fade>
                          <Fade right cascade when={this.state.showLO} duration={500}>
                            <div>
                              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>

                              <a href="">What We Do</a>
                              <a onClick={e => this.handleToggleSlide(`LO`)}>Back</a>
                            </div>
                          </Fade>
                        </MoreInfoContent>
                      </MoreInfoContainer>
                    </MoreInfo>
                  </Slide>
                </SlideLO>
              </Slides>
              <Col active={state.THINK} target={`showTHINK`} bgImage={`https://media.giphy.com/media/rKtN7XLseRCuI/giphy.gif`} {...childrenProps}>
              </Col>
              <Col active={state.LO} target={`showLO`} bgImage={`https://cdn.dribbble.com/users/588022/screenshots/1780019/skull_scan.gif`} {...childrenProps}>
              </Col>
              <Cross {...childrenProps} />
              <VerticalCenter>
                <Container fluid>
                  <Content>
                    <LogoLarge>
                      <Logo {...childrenProps}>
                        <LogoFragment left>
                        {/*
      <Timeline
        wrapper={
         <svg
          version="1.1"
          id="THINK"
          xmlns="http://www.w3.org/2000/svg"
        />}
        from={{
          svgDraw: [0, 0.5],
        }}
        to={{
          svgDraw: [1, 0],
        }}
        target={
          <path d="M22.4,19.7H0V2.1h64.7v17.6H42.3v49.6h-20L22.4,19.7z M133.1,2.1v67.2h-20V44.6H89.5v24.8h-20V2.1h20v23.8h23.6V2.1H133.1z M139,2.1h20v67.2h-20V2.1z M231.7,2.1h20v67.2h-20V2.1z M225.8,2.1v67.2h-16l-25.5-33.2v33.2h-19.4V2.1h15.9l25.5,33.2V2.1H225.8z M274.9,69.3l-24.5-33.9L275,2.1h21.7l-23.3,32.5l24.8,34.8H274.9z" fill="none" stroke="black" strokeWidth="2"/>
        }
        duration={2}
      >
      {/*
  <path d="M22.4,19.7H0V2.1h64.7v17.6H42.3v49.6h-20L22.4,19.7z"/>
  <path d="M133.1,2.1v67.2h-20V44.6H89.5v24.8h-20V2.1h20v23.8h23.6V2.1H133.1z"/>
  <path d="M139,2.1h20v67.2h-20V2.1z"/>
  <path d="M231.7,2.1h20v67.2h-20V2.1z"/>
  <path d="M225.8,2.1v67.2h-16l-25.5-33.2v33.2h-19.4V2.1h15.9l25.5,33.2V2.1H225.8z"/>
  <path d="M274.9,69.3l-24.5-33.9L275,2.1h21.7l-23.3,32.5l24.8,34.8H274.9z"/>
      
        <Tween
          from={{
            svgDraw: [0, 0.5],
          }}
          to={{
            svgDraw: [1, 0],
          }}
          duration={2}
        />
      </Timeline>
                        */}

                          <THINK
                            onMouseEnter={e => this.handleMouseHover(`THINK`, `enter`)}
                            onMouseLeave={e => this.handleMouseHover(`THINK`, `leave`)}
                            onClick={e => this.handleToggleSlide(`THINK`)}
                            {...childrenProps}
                          >
                            <LogoTHINK />
                          </THINK>
                          <ShotCalling
                            active={!state.showTHINK && state.THINK}
                            onMouseEnter={e => this.handleMouseHover(`THINK`, `enter`)}
                            onMouseLeave={e => this.handleMouseHover(`THINK`, `leave`)}
                            onClick={e => this.handleToggleSlide(`THINK`)}
                          >
                            Start Thiking
                          </ShotCalling>
                        </LogoFragment>
                        <LogoFragment right>
                          <LO
                            onMouseEnter={e => this.handleMouseHover(`LO`, `enter`)}
                            onMouseLeave={e => this.handleMouseHover(`LO`, `leave`)}
                            onClick={e => this.handleToggleSlide(`LO`)}
                            {...childrenProps}
                          >
                            <LogoLO />
                          </LO>
                          <ShotCalling
                            active={!state.showTHINK && state.LO}
                            onMouseEnter={e => this.handleMouseHover(`LO`, `enter`)}
                            onMouseLeave={e => this.handleMouseHover(`LO`, `leave`)}
                            onClick={e => this.handleToggleSlide(`LO`)}
                          >
                            Surprise yourself
                          </ShotCalling>
                        </LogoFragment>
                        <Ref ref={ref} />
                        {/*
                        <Status>
                          <div>Device Type: {detectIt.deviceType}</div>
                          <div>distance: {proximity}</div>
                          <div>move: {move}</div>
                          <div>lockAt: {lockAt}</div>
                          <div>treshold: {treshold}</div>
                        </Status>
                        */}
                      </Logo>
                    </LogoLarge>

                  </Content>
                </Container>
              </VerticalCenter>
              <div style={{
                position: `absolute`,
                top: `0`,
                right: `0`,
                padding: `4rem 3rem`,
                width: `22%`,
                height: `20%`,
                fontSize: `14px`
              }}>
                <hr
                  style={{
                    width: `3rem`,
                    top: `2.5rem`,
                    backgroundColor: `#6551ff`,
                    position: `absolute`,
                    height: `2px`
                  }}
                />
                We work with ambitious companies creating <b>products</b>, <b>services</b> and <b>experiences</b> that deliver sustainable, profitable growth.
              </div>
            </Section>
          );
        }}
      </ProximityFeedback>
    );
  }
}
Component.propTypes = {
  treshold: PropTypes.number,
  lockAt: PropTypes.number,
  startAt: PropTypes.number,
  centerAdjustment: PropTypes.number
};
Component.defaultProps = {
  treshold: 10,
  lockAt: 100,
  startAt: 240,
  centerAdjustment: 10.75
};
