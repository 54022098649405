import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import logger from 'utils/logger';
// import { Container, Row } from 'components/GridSystem';

const SectionIntro = styled.div`
  ${tw`
    py-1.5
    flex
    items-stretch
    content-center
    flex-wrap
  `};
  img {
    ${tw`
      max-w-full
    `};
  }
  h1 {
    ${tw`
      xl:text-hero-lg
    `};
  }
  p {
  ${tw`
    xl:text-lg
  `};
  ${(props) => {
    const { col } = props;
    return [
      col === 2 && tw`
        xl:w-1/2
        xl:inline-block
        xl:pr-1
      `
    ];
  }};
  }
`;
const SectionIntroContent = styled.div`
  ${tw`
    xl:text-2xl
    font-light
    tracking-tight
    pb-2.5
  `};
  ${(props) => {
    const { light } = props;
    return [
      light && tw`text-white`
    ];
  }};
`;

// const animationDuration = 600;

export default class Component extends React.Component {
  render () {
    const {
      children,
      label,
      right,
      // intro,
      // src,
      featuredImage,
      title
    } = this.props;
    return (
      <SectionIntro>
        <div>
          <h1>
            {`Parnassian on sanglant linage et divorce rodding`}
          </h1>
          <SectionIntroContent>
            {`Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol`}
          </SectionIntroContent>
        </div>
      </SectionIntro>
    );
  }
}
Component.propTypes = {
  children: PropTypes.node,
  intro: PropTypes.string,
  right: PropTypes.bool,
  title: PropTypes.string,
  featuredImage: PropTypes.object,
  label: PropTypes.string,
  src: PropTypes.string
};
Component.defaultProps = {
  children: ``,
  right: false,
  featuredImage: null,
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  title: 'Parnassian on sanglant linage et divorce rodding',
  label: 'ApproachDescription',
  src: '/testing/thumbnail-small.jpg'
};
