import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';

const VideoBackground = styled.div`
  ${tw`
    absolute
  `}
  left: 0; right: 0;
  top: 0; bottom: 0;
  :after {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(0,0,0,.5);
  }
`;
const Cover = styled.div`
  ${tw`
    min-h-screen
    flex
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
  `};
  video {
    ${tw`
      absolute
    `};
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }
  ${(props) => {
    const { background } = props;
    const bgColor = props.bgColor ? props.bgColor : `bg-gray-200`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      !isLight ? tw`text-white` : tw`text-black`,
      backgroundColor && `background-color: ${backgroundColor};`,
      background && (background.image && `background-image: url('${background.image.substring(0, background.image.indexOf('?'))}?w=1200&q=90');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`),
      background && (`
        :before {
          content: "";
          position: absolute;
          left: 0; right: 0;
          top: 0; bottom: 0;
          background: rgba(0,0,0,.5);
        }`)
    ];
  }};
`;
const Content = styled.div`
  ${tw`
    flex-2 
    max-w-full
    sm:max-w-11/12
    md:max-w-3/4
    lg:max-w-2/3
    xl:max-w-1/2
  `}
`;

const Title = styled.h1`
  ${tw`
    text-superhero-lg
    font-hairline
  `};
  b{
    ${tw`font-bold`};
  }
`;
const Text = styled(MDXRenderer)`
  ${tw`
    mb-0
    text-lg
    text-gray-200
    font-light
    tracking-wide
  `};
`;

export default class Component extends React.Component {
  render () {
    const {
      children,
      label,
      content,
      background,
      title
    } = this.props;
    const titleSplit = title && title.split(` `);
    const animationDuration = 600;
    return (
      <Cover {...this.props}>
        {background && (
          background.video && (
            <VideoBackground>
              <video autoPlay loop muted ref={(player) => { this.player = player }}>
                <source src={background.video} type="video/mp4" />
              </video>
            </VideoBackground>
          )
        )}
        <Container>
          <Row>
            <Content>
              {label && (
                <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration / 2}>
                  <SectionLabel label={label} />
                </Fade>
              )}
              <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <Title>
                  <b>
                    {titleSplit && titleSplit[0]}
                  </b>
                  {titleSplit && titleSplit[1] && (
                    <br />
                  )}
                  {titleSplit && titleSplit[1]}
                </Title>
              </Fade>
              {content && (
                <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                  <Text>
                    {content}
                  </Text>
                </Fade>
              )}
            </Content>
          </Row>
        </Container>
      </Cover>
    );
  }
}

Component.propTypes = {
  children: PropTypes.node
};

Component.defaultProps = {
  children: ``
};
