import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Color from 'color';
import colors from 'styles/Color';
// import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';

const ApproachDescription = styled.section`
  ${tw`
    flex
    pb-6
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
  `};
  > p {
    ${tw`xl:w-1/2`}
  }
`;
const Content = styled.section`
  ${tw`
    flex
    pb-3
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
    xl:w-4/5
  `};
  > p {
    ${tw`xl:w-1/2 md:pr-6`}
  }
`;

// const animationDuration = 600;

export default class Component extends React.Component {
  render () {
    const {
      children,
      label,
      right,
      // intro,
      // src,
      featuredImage,
      title
    } = this.props;
    return (
      <ApproachDescription>
        <Container>
          <Row>
            <Content>
              {children}
            </Content>
          </Row>
        </Container>
      </ApproachDescription>
    );
  }
}
Component.propTypes = {
  children: PropTypes.node,
  intro: PropTypes.string,
  right: PropTypes.bool,
  title: PropTypes.string,
  featuredImage: PropTypes.object,
  label: PropTypes.string,
  src: PropTypes.string
};
Component.defaultProps = {
  children: ``,
  right: false,
  featuredImage: null,
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  title: 'Parnassian on sanglant linage et divorce rodding',
  label: 'ApproachDescription',
  src: '/testing/thumbnail-small.jpg'
};
