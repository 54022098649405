import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
  navigate
} from 'gatsby';

import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';
import { ReactComponent as THINKLO } from 'assets/THINKLO-logo-light.svg';

import {
  // BgImg,
  // Img,
  Button
} from 'components/Elements';

const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;
const VideoBackground = styled.div`
  ${tw`
    absolute
  `}
  left: 0; right: 0;
  top: 0; bottom: 0;
  :after {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(0,0,0,.5);
  }
`;
const Cover = styled.div`
  ${tw`
    min-h-screen
    flex
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
    relative
    overflow-hidden
  `};
  video {
    ${tw`
      absolute
      min-h-full
      min-w-full
    `};
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }
  ${(props) => {
    const { background } = props;
    const bgColor = props.bgColor ? props.bgColor : `bg-gray-200`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      !isLight ? tw`text-white` : tw`text-black`,
      backgroundColor && `background-color: ${backgroundColor};`,
      background && (background.image && `background-image: url('${background.image.substring(0, background.image.indexOf('?'))}?w=1200&q=90');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`),
      background && (`
        :before {
          content: "";
          position: absolute;
          left: 0; right: 0;
          top: 0; bottom: 0;
          background: rgba(0,0,0,.5);
        }`)
    ];
  }};
`;
const Content = styled.div`
  ${tw`
    flex-2 
    pb-3
    max-w-full
    sm:max-w-11/12
    md:max-w-3/4
    lg:max-w-2/3
    xl:max-w-2/5
    relative
    z-3
  `};
  svg {
    ${tw`
      relative
      mb-1
      fill-white
      xxs:h-0.75
      xs:h-1
      sm:h-2.5
      md:h-3.5
      lg:h-4
      xl:h-5.5
    `};
    top: 1.5rem;
    & path {
    }
  }
`;

const Title = styled.h1`
  ${tw`
    text-white
    text-4xl
    xl:tracking-tight
    xl:text-hero-lg
    font-hairline
  `};
  span{
    ${tw`mr-0.75`};
  }
  b{
    ${tw`font-bold`};
  }
  svg {
    ${tw`
      h-1.75
      xl:mt-1
      xl:h-4
      relative
      mb-0
    `};
    top: 4px;
  }
`;
const Text = styled.div`
  ${tw`
    mb-0
    text-lg
    xl:text-lg
    text-gray-200
    font-light
    tracking-wide
  `};
  > p {
    ${tw`
      mb-0.75
    `};
  }
`;
const CTA = styled.a`
  ${tw`
    font-semibold
    mx-0.25
    relative
    tracking-wider
    uppercase
    text-md
    cursor-pointer
    text-gray-200
    rounded
    pl-1
    pr-0.25
    py-0.75
    border-2
    border-solid
    relative
  `};
  top: 1em;
  left: -4px;
  border-color: #545360;
  & span {
    ${tw`
      inline-block
    `};
    &:before {
      content: "";
      ${transition};
      ${tw`
        relative
        inline-block
        bg-gray-500
        mb-4px
        w-4px
        mx-0.5
        h-2px
      `};
    }
  }
  &:hover,
  &:focus {
    ${tw`
      text-white
    `};
    border-color: #36309F;
    & span {
      &:before {
        content: "";
        ${tw`
          bg-brand-primary-500
          mx-1
          w-8
        `};
      }
    }
  }
`;

const Line = styled.hr`
  ${tw`
    my-3
    ml-0
    text-lg
    bg-brand-primary
  `};
`;
const Contact = styled.div`
  ${tw`
    flex
    leading-none
  `};
  > div {
    ${tw`
      w-full
      md:w-1/3
      pr-1
    `};
    > div {
      ${tw`
        text-sm
      `};
      b {
        ${tw`
          inline-block
          font-semibold
          mb-0
        `};
        min-width: 100px;
      }
    }
    hr {
      ${tw`
        my-1
        pb-0
      `};
    }
  }
`;

class Component extends React.Component {
  render () {
    const {
      children,
      label,
      blocks,
      content,
      background,
      toggleMenu,
      title
    } = this.props;
    console.log(`blocks`, blocks)
    const animationDuration = 600;
    return (
      <Cover {...this.props}>
        {background && (
          background.video && (
            <VideoBackground>
              <video autoPlay loop muted ref={(player) => { this.player = player }}>
                <source src={background.video} type="video/mp4" />
              </video>
            </VideoBackground>
          )
        )}
        <Container>
          <Row>
            <Content>
                <Title>
                  <span>
                    Welcome to
                  </span>
                  <THINKLO />
                </Title>
                <Line />
              {content && (
                  <Text>
                    <p>
                      A strategic design firm, working with ambitious companies to create and evolve brands, products, and services that yield sustainable, profitable growth.
                    </p>
                    <p>
                      We can help you make better things or make things better.
                    </p>

                    {/* <MDXRenderer>
                      {blocks[0].content.childMdx.body}
                    </MDXRenderer>
                    <div>
                      <p>
                        We are currently making big changes to our website. For new business or additional information reach us at:
                      </p>
                    </div>
                    <Contact>
                      <div>
                        <b>New Business</b>
                        <hr />
                        <div>
                          <a href="mailto:biz@thinklo.com">biz@thinklo.com</a>
                          <br />
                          <a href="tel:+12139081620">+1 (213) 908-1620</a>
                          <br />
                          <br />
                          <b>Los Angeles:</b> <a href="mailto:la@thinklo.com">la@thinklo.com</a>
                          <br />
                          <b>Hong Kong:</b> <a href="mailto:hk@thinklo.com">hk@thinklo.com</a>
                          <br />
                          <b>Bogota:</b> <a href="mailto:bog@thinklo.com">bog@thinklo.com</a>
                        </div>
                      </div>
                      <div>
                        <b>THINKLO</b> Global
                        <hr />
                        <div>
                          <b>Rei Romero</b>
                          <br />
                          <a href="mailto:rei@thinklo.com">rei@thinklo.com</a>
                          <br />
                          <a href="tel:+14243190893">+1 (424) 319-0893</a>
                        </div>
                      </div>
                      <div>
                        <b>PR</b>
                        <hr />
                        <div>
                          <b>Laura Jimenez</b>
                          <br />
                          <a href="mailto:lau@thinklo.com">lau@thinklo.com</a>
                          <br />
                          <a href="tel:+573138630248">+57 (313) 863-0248</a>
                        </div>
                      </div>
                    </Contact>
                    {content}
                    */}
                  <CTA onClick={toggleMenu}>
                    More
                    <span/>
                  </CTA>
                  {/*
                    </Fade>
                    <SectionLabel label={blocks[2].title} />
                    <MDXRenderer>
                      {blocks[2].content.childMdx.body}
                    </MDXRenderer>
                    */}
                  </Text>
              )}
            </Content>
          </Row>
        </Container>
      </Cover>
    );
  }
}

Component.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.node
};

Component.defaultProps = {
  children: ``
};

const mapStateToProps = ({ menu }) => {
  return { menu };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleMenu: () => dispatch({ type: `TOGGLE_MENU` })
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

class ReduxComponent extends React.Component {
  render(props) {
    return (
      <ConnectedComponent {... this.props}/>
    );
  }
}

export default ReduxComponent;
