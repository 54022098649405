import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
// import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';

const Expertise = styled.section`
  ${tw`pb-6`};
  h1 {
    ${tw`
      font-light
      text-xl
      xxs:text-2xl
      xs:text-3xl
      sm:text-4xl
      md:text-h2
      lg:text-h1
      leading-h1-loose
    `};
  }
  p {
    ${tw`text-lg`}
  }
`;
const ColText = styled.div`
  ${tw`
    w-full
    md:pr-2
    lg:pr-3
    md:w-7/12
    lg:w-3/5
    xl:w-2/5
  `};
`;

const ColImg = styled.div`
  ${tw`
    w-full
    min-h-12
    mb-3
    md:text-right
    pr-0.5
    sm:pr-1
    md:pr-2
    lg:pr-2
    xl:pr-1/12
    md:w-5/12
    lg:w-2/5
    xl:w-3/5
    self-stretch
  `};
  div {
    ${tw`bg-gray-200 h-full`}
  }
`;

const animationDuration = 600;

export default class Component extends React.Component {
  render () {
    const { label, intro } = this.props;
    return (
      <Expertise>
        <Container fluid align={`center`}>
          <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration / 2}>
            <SectionLabel label={label} />
          </Fade>
          <Row reverse>
            <ColText>
              <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <h1>
                  {intro}
                </h1>
                <p>{intro}</p>
                <p>{intro} {intro}</p>
              </Fade>
            </ColText>
            <ColImg>
            <div>
              Img
            </div>
            </ColImg>
          </Row>
        </Container>
      </Expertise>
    );
  }
}
Component.propTypes = {
  intro: PropTypes.string,
  label: PropTypes.string
};
Component.defaultProps = {
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  label: 'Expertise'
};
