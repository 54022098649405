import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';

const Cover = styled.div`
  ${tw`
    min-h-screen
    flex
    mb-3
    top-0
    items-stretch
    content-center
    flex-wrap
    relative
  `};
  ${(props) => {
    const { background } = props;
    const bgColor = props.bgColor ? props.bgColor : `bg-gray-200`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      !isLight ? tw`text-white` : tw`text-black`,
      backgroundColor && `background-color: ${backgroundColor};`,
      background && (background.image && `background-image: url('${background.image.substring(0, background.image.indexOf('?'))}?w=1200&q=90');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`),
      background && (`
      :before {
        content: "";
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background: rgba(0,0,0,.5);
      }`)
    ];
  }};
`;
const Content = styled.div`
  ${tw`
    flex-2 
    max-w-full
    sm:max-w-11/12
    md:max-w-3/4
    lg:max-w-2/3
    xl:max-w-1/2
  `};
  min-height: 430px;
`;

const Title = styled.h1`
  ${tw`
    relative
    xl:text-superhero-lg
    font-light
    font-serif
    tracking-tight
    leading-h1-loose
  `};
  left: -0.25rem;
  b {
    ${tw`font-sbold`};
  }
  span {
    ${tw`
      font-serif
      xl:text-superhero-lg
    `};
  }
  small {
    ${tw`font-light`};
  }
`;
const Text = styled(MDXRenderer)`
  ${tw`
    mb-0
    text-lg
    text-gray-200
    font-light
    tracking-wide
  `};
`;

export default class Component extends React.Component {
  render () {
    const {
      label,
      content,
      title
    } = this.props;
    const titleSplit = title && title.split(` `);
    const animationDuration = 600;
    return (
      <Cover {...this.props}>
        <Container>
          <Row>
            <Content>
              <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration / 2}>
                <SectionLabel label={title} />
              </Fade>
              <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <Title>
                  {/*
                  {titleSplit.map((word, i) => {
                    const separator = i === titleSplit.length - 1
                      ? <small>.</small>
                      : ` `;
                    return (
                      word.length > 3 ? (
                        <b>
                          {word}
                          {separator}
                        </b>
                      ) : (
                        <span>
                          {word}
                          {separator}
                        </span>
                      )
                    );
                  })}
                  */}
                 Creating value by reinventing the core, together.
                </Title>
              </Fade>
            </Content>
          </Row>
        </Container>
      </Cover>
    );
  }
}

Component.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.node
};

Component.defaultProps = {
  children: ``
};
