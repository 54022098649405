import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Color from 'color';
import colors from 'styles/Color';
// import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';
import BgImg from 'components/Elements/BgImg';

const ServiceArea = styled.section`
  ${tw`
  `};
  ${(props) => {
    const bgColor = props.bgColor ? props.bgColor : `gray-800`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      `background-color: ${backgroundColor};`,
      !isLight ? tw`text-white` : tw`text-black`,
      props.border && [
        tw`border-solid border-0 border-b`,
        !isLight ? `border-color: rgba(255,255,255,0.2);` : `border-color: rgba(0,0,0,0.2);`
      ]
    ];
  }};
  h2 {
    ${tw`
      text-xl
      xxs:text-2xl
      xs:text-3xl
      sm:text-4xl
      md:text-h2
      lg:text-h1
      leading-h1-loose
    `}
  }
`;
const Title = styled.p`
  ${tw`
    text-sm
    xl:text-3xl
    xl:leading-h1-loose
    font-thin
  `};
`;
const ColText = styled.div`
  ${tw`
    flex-col
    py-6
    w-full
    md:pr-2
    lg:pr-3
    xl:px-1/12
    md:w-11/12
    lg:w-3/5
    xl:w-1/2
  `};
  ${(props) => {
    const bgColor = props.bgColor ? props.bgColor : `gray-800`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      props.right && tw`-ml-4`,
      !isLight ? tw`text-white` : tw`text-black`
    ];
  }};
`;

const ColImg = styled.div`
  ${tw`
    flex-col
    w-full
    mb-0
    md:text-right
    md:w-11/12
    lg:w-2/5
    xl:w-1/2
    self-stretch
  `};
  div {
    ${tw`h-full flex`}
    img {
      ${tw`w-full m-0`}
      transition: all 600ms cubic-bezier(.1,.55,.4,1);
    }
    &:hover{
      img {
        ${tw`opacity-80`}
      } 
    }
  }
`;

const animationDuration = 600;

export default class Component extends React.Component {
  render () {
    const {
      children,
      label,
      right,
      // intro,
      // src,
      featuredImage,
      title
    } = this.props;
    return (
      <ServiceArea {...this.props}>
        <Container fluid>
          <Row reverse={!right}>
            <ColImg>
              <Fade right duration={animationDuration} distance={`0%`} delay={animationDuration}>
                <div style={{ position: `relative`, backgroundColor: `rgba(80,80,80,0.1)` }}>
                  {featuredImage && (
                    featuredImage.cover && <BgImg {...featuredImage.cover} />
                  )}
                </div>
              </Fade>
            </ColImg>
            <ColText {...this.props}>
              <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <SectionLabel label={label} />
                <Title>
                  {title}
                </Title>
              </Fade>
              <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                {children}
              </Fade>
            </ColText>
          </Row>
        </Container>
      </ServiceArea>
    );
  }
}
Component.propTypes = {
  children: PropTypes.node,
  intro: PropTypes.string,
  right: PropTypes.bool,
  title: PropTypes.string,
  featuredImage: PropTypes.object,
  label: PropTypes.string,
  src: PropTypes.string
};
Component.defaultProps = {
  children: ``,
  right: false,
  featuredImage: null,
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  title: 'Parnassian on sanglant linage et divorce rodding',
  label: 'ServiceArea',
  src: '/testing/thumbnail-small.jpg'
};
