import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Color from 'color';
import {
  navigate,
  Link
} from 'gatsby';
// import logger from 'utils/logger';
import Colors from 'styles/Color';
import { Container, Row } from 'components/GridSystem';
import { ReactComponent as THINKLO } from 'assets/THINKLO-logo-light.svg';

const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;
const Footer = styled.section`
  ${tw`
    flex
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
    md:min-h-screen-20
  `};
  ${(props) => {
    const bgColorProcessed = (props.bgColor.startsWith('#') || props.bgColor.startsWith('rgb') || props.bgColor.startsWith('hsl')) ? props.bgColor : Colors[props.bgColor];
    const color = Color(bgColorProcessed).isLight() ? `light` : `dark`;
    return [
      props.bgColor && `background-color: ${bgColorProcessed};`,
      color === `dark` ? tw`text-gray-300` : tw`text-black`
    ];
  }};
  h1 {
    ${tw`
      font-thin
      font-serif
      tracking-tight
      lg:leading-hero-xl-loose
      lg:text-hero-xl
      xl:max-w-5/6
      xl:text-hero-xxl
    `};
  }
  a {
    &:focus,
    &:hover {
      ${tw`text-white`};
    }
  }
`;
const FirstCol = styled.div`
  ${tw`
    flex-2
    pt-1
    pb-2
    md:pt-0
    md:pb-0
    xl:w-1/3
  `};
  svg {
    ${tw`
      relative
      mb-1
      fill-gray-300
    `};
    top: 1.5rem;
    height: 22px;
    & path {
    }
  }
`;
const Terms = styled.small`
  ${tw`
    text-sm
    tracking-tight
    opacity-80
  `};
  > div {
    ${tw`h-1.75`};
  }
  a {
    &:not(:last-child):after {
      ${tw`
        inline-block
        text-gray-500
        px-0.5
      `};
      content: ' | ';
    }
  }
`;
const Menu = styled.div`
  ${tw`
    hidden
    md:block
    flex-2 
    xl:w-2/3
  `};
`;

const Nav = styled.ul`
  ${tw`
    list-none
    m-0
    p-0
    text-right
  `};
`;
const NavItem = styled.li`
  ${tw`
    inline-block
    ml-3
    mb-0
    py-1
  `};
  & > a {
    cursor: pointer;
    &:focus,
    &:hover {
      > i {
        ${tw`bg-white`};
        &:nth-child(1) {
          ${tw`
          `};
          left: -4px
        }
        &:nth-child(3) {
          ${tw`
          `};
          left: 4px
        }
        &:nth-child(even) {
          ${tw`
          `};
        }
      }
    }
    > i {
      ${tw`
        relative
        top-2
        inline-block
        px-0
        bg-gray-300
        mx-3px
        w-0.25
        h-0.25
        rounded
      `};
      left: 0;
      right: 0;
      top: 1.85rem;
      ${transition}
    }
  }
`;

class Component extends React.Component {
  render () {
    const {
      label,
      excerpt,
      intro,
      bgColor,
      toggleMenu
    } = this.props;
    const animationDuration = 600;
    const copyright = (<span>© 2011-{new Date().getFullYear()} <b>THINKLO</b> Group. </span>);
    const nav = [
      {
        id: `1`,
        label: `About Us`,
        to: `/about-us`
      },
      {
        id: `2`,
        label: `Contact`,
        to: `/contact`
      },
    ];
    return (
      <Footer bgColor={bgColor}>
        <Container>
          <Row>
            <FirstCol>
              <THINKLO
                onClick={() => navigate(`/${`landing`}`)}
                role={`link`}
              />
              <br />
              <Terms>
                {copyright}
                {/*
                <Link>
                  Terms of Use
                </Link>
                <Link>
                  Privacy Policy
                </Link>
                <Link>
                  Cookies
                </Link>
                */}
              </Terms>
            </FirstCol>
            <Menu>
              <Nav>
                { nav && nav.map((item) => {
                  const {
                    id,
                    label,
                    to
                  } = item;
                  return (
                    <NavItem key={`nav-footer-${id}`}>
                      <Link to={to}>
                        {label}
                      </Link>
                    </NavItem>
                  );
                })}
                <NavItem>
                  <a onClick={toggleMenu}>
                    <i />
                    <i />
                    <i />
                  </a>
                </NavItem>
              </Nav>
            </Menu>
          </Row>
        </Container>
      </Footer>
    );
  }
}
Component.propTypes = {
  intro: PropTypes.string,
  label: PropTypes.string,
  bgColor: PropTypes.string,
  excerpt: PropTypes.string
};
Component.defaultProps = {
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  excerpt: 'We tailor our services to meet the needs of each client, and work together every step of the way to bring excellent products, services and experiences to market. We ground our work in market opportunity, stay relentlessly focused on outcomes and help our clients grow internal capabilities. Whatever it takes, frog makes it real.',
  label: 'Intro',
  bgColor: 'black'
};

const mapStateToProps = ({ menu }) => {
  return { menu };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleMenu: () => dispatch({ type: `TOGGLE_MENU` })
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

class ReduxComponent extends React.Component {
  render () {
    return (
      <ConnectedComponent />
    );
  }
}

export default ReduxComponent;
