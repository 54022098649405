import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import { MDXRenderer } from 'gatsby-plugin-mdx';
// import { MDXProvider } from "@mdx-js/react";
import Fade from 'react-reveal/Fade';
import Color from 'color';
import Colors from 'styles/Color';
// import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';

const Intro = styled.section`
  ${tw`
    min-h-screen
    flex
    mb-0
    top-0
    items-stretch
    content-center
    flex-wrap
  `};
  ${(props) => {
    const { background } = props;
    const bgColorProcessed = (props.bgColor.startsWith('#') || props.bgColor.startsWith('rgb') || props.bgColor.startsWith('hsl')) ? props.bgColor : Colors[props.bgColor];
    const color = Color(bgColorProcessed).isLight() ? `light` : `dark`;
    return [
      props.bgColor && `background-color: ${bgColorProcessed};`,
      background && (background.image && `background-image: url('${background.image}');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`),
      color === `dark` ? tw`text-white` : tw`text-black`
    ];
  }};
  h1 {
    ${tw`
      font-thin
      font-serif
      tracking-tight
      lg:leading-hero-xl-loose
      lg:text-hero-xl
      xl:max-w-5/6
      xl:text-hero-xxl
    `};
  }
`;
const Content = styled.div`
  ${tw`
    flex-2 
    max-w-full
  `};
  min-height: 430px;
`;

const Red = styled.span`
  ${tw`
    inline-block
  `};
`;

const Text = styled.p`
  ${tw`
    mb-0
    tracking-wide
    text-lg
    max-w-full
    sm:max-w-11/12
    md:max-w-3/4
    lg:max-w-2/3
    xl:max-w-1/2
  `};
  ${(props) => {
    const bgColorProcessed = (props.bgColor.startsWith('#') || props.bgColor.startsWith('rgb') || props.bgColor.startsWith('hsl')) ? props.bgColor : Colors[props.bgColor];
    const color = Color(bgColorProcessed).isLight() ? `light` : `dark`;
    return [
      props.bgColor && `background-color: ${bgColorProcessed};`,
      color === `dark` ? tw`text-gray-200` : tw`text-gray-700`
    ];
  }};
`;

export default class Component extends React.Component {
  render() {
    const {
      label,
      content,
      intro,
      bgColor,
      background
    } = this.props;
    const animationDuration = 600;
    return (
      <Intro background={background} bgColor={bgColor}>
        <Container>
          <Row>
            <Content>
              <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration / 2}>
                <SectionLabel label={`Page not found`} />
              </Fade>
              <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <h1>
                  Schrödinger's webpage
                </h1>
              </Fade>
              <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                <Text bgColor={bgColor}>
                  <div>
                    <p>At some point, this webpage was locked in a box with a flask of poison and a radioactive source. Prior to your discovery, this webpage was simultaneously active and inactive. As you have attempted to access the webpage and its superposition within, the system has collapsed.</p>
                    <p>Please check out the menu or footer links to try accessing a different page.</p>
                  </div>
                </Text>
              </Fade>
            </Content>
          </Row>
        </Container>
      </Intro>
    );
  }
}
Component.propTypes = {
  intro: PropTypes.string,
  label: PropTypes.string,
  bgColor: PropTypes.string,
  content: PropTypes.string
};
Component.defaultProps = {
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  content: 'We tailor our services to meet the needs of each client, and work together every step of the way to bring excellent products, services and experiences to market. We ground our work in market opportunity, stay relentlessly focused on outcomes and help our clients grow internal capabilities. Whatever it takes, frog makes it real.',
  label: 'Intro',
  bgColor: 'white'
};
