import React from 'react';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import colors from 'styles/Color';
import logger from 'utils/logger';
import { navigate, Link } from 'gatsby';

import {
  BgImg
} from 'components/Elements';
import { SectionLabel } from 'components/Text';
import { Container, Row } from 'components/GridSystem';
import { ReactComponent as Venn } from 'assets/venn.svg';

const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;
const ApproachCards = styled.section`
  ${tw`
  `}
`;
const ApproachArea = styled(Link)`
  ${tw`
    xl:mx-1/24
    w-full
    block
    border-0
    border-b
    border-solid
    border-gray-100
    bg-gray-800
    text-white
  `};
  h1 {
    ${tw`
      pt-1
      font-light
      font-serif
      tracking-tight
      leading-h1-loose
      text-7xl
    `}
  }
  & * {
    ${tw`
      text-white
    `};
  }
  & span {
    ${tw`
      text-gray-300
    `};
  }
  ${transition};
  &:hover,
  &:focus {
    ${tw`
      bg-brand-primary-700
      text-white
    `};
    & span {
      ${tw`
        text-white
      `};
    }
    & span > span {
      &:before {
        content: "";
        ${tw`
          w-12
          bg-brand-primary-300
          mx-2.5
        `};
      }
    }
  }
`;
const ApproachAreaContent = styled.div`
  ${tw`
    py-6
    xl:w-1/2
    xl:px-1/16
  `}
  ${({ reverse }) => {
    return [
      // reverse ? tw`` : tw``
    ];
  }};
`;
const ApproachAreaImage = styled.div`
  ${tw`
    bg-gray-100
    xl:w-1/2
  `}
`;
const Thumbnail = styled.div`
  ${tw`
    relative
    h-12
    block
    bg-gray-200
    pt-aspect-ratio-16/9
    md:h-full
    xl:pt-aspect-ratio-16/9
    mb-0
  `};
  ${({ src }) => {
    return [
      src && `
        background-image: url('${src}');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      `
    ];
  }};
`;
const CTAMore = styled.span`
  ${tw`
    font-semibold
    mx-0.25
    relative
    tracking-wider
    uppercase
    text-md
  `};
  & span {
    ${tw`
      inline-block
    `};
    &:before {
      content: "";
      ${transition};
      ${tw`
        relative
        mx-1
        inline-block
        bg-gray-500
        mb-4px
        w-3
        h-2px
      `};
    }
  }
`;

// const ApproachCards = styled.section`
//   ${tw`
//   `}
//   ${({ active }) => {
//     return [
//       `
//       background-image: linear-gradient(
//         29deg,
//         ${colors[`brand-primary-700`]},
//         ${active ? colors[`gray-100`] : colors[`brand-primary-900`]}
//       );`
//     ];
//   }};
// `;

const SectionTitle = styled.div`
  ${tw`
    py-2
    text-white
  `}
  h1 {
    ${tw`
      block
      font-serif
      font-light
      tracking-tight
      xl:text-hero
    `}
  }
  p {
    ${tw`
      xl:text-4xl
      font-light
      tracking-tighter
    `}
  }
`;

const CardsContainer = styled.div`
  ${tw`
  `}
`;

const ApproachCard = styled(Link)`
  ${tw`
    block
    font-light
    mb-0
    xl:text-lg
    opacity-95
    cursor-pointer
    transition-all
    transition-300
    transition-ease
  `};
  ${({ active }) => {
    return [
      active === `pasive` && tw`opacity-50`,
      active === `active` && tw`opacity-100`
    ];
  }};
  > h1 {
    ${tw`
      transition-600
    `};
  }
  &:hover,
  &:focus {
    > h1 {
      ${tw`
        text-white
        transition-600
      `};
      &::after {
        ${tw`
          w-full
          transition-600
        `};
      }
    }
  }
`;
const Title = styled.h1`
  ${tw`
    font-light
    relative
    lg:text-hero-xl
    lg:leading-hero-xl
    xl:text-h2
    xl:leading-hero
    ml-3
    mb-4
    inline-block
    text-gray-300
    tracking-tight
    transition-all
    transition-300
    transition-ease
  `};
  & a {
    text-white
  }
  &::after {
    ${tw`
      block
      absolute
      bg-brand-primary
      w-0
      mt-2
      h-2px
      transition-all
      transition-300
      transition-ease
    `};
    content: '';
  }
  &::before {
    ${tw`text-sm tracking-normal font-bold block pt-0.5 text-brand-primary-400 absolute top-0 left--3`};
  };
  ${({ index }) => {
    return [
      `&::before {
        content: '0${index + 1}.'
      };`
    ];
  }};
`;

const ColMenu = styled.div`
  ${tw`
    pt-6
    pb-4
    xl:w-1/2
    text-white
    pl-1/12
  `}
`;
const ColDescription = styled.div`
  ${tw`
    py-6
    xl:w-1/2
    relative
    overflow-y-scroll
  `}
`;
const Description = styled.div`
  ${tw`
    absolute
    top-6
    pb-6
    left-0
    xl:w-4/5
    opacity-0
    transition-all
    transition-300
    transition-ease
    z-0
    text-white
  `}
  h2 {
    ${tw`
      font-serif
      font-light
      xl:text-hero
      xl:leading-tight
    `}
  }
  ${({ active }) => {
    return [
      active && tw`opacity-100 z-10`
    ];
  }};
`;

const VennDiagram = styled(Venn)`
  ${tw`w-2/3 overflow-visible`}
  path {
    ${tw`
      fill-white
      transition-all
      transition-600
      transition-ease
    `}
    stroke: rgba(0,0,0,.25);
    stroke-width: 0.4;
    &:hover {
      ${tw`
        transition-300
      `}
       &:nth-child(1) {${tw`fill-gray-300`}}
       &:nth-child(2) {${tw`fill-gray-400`}}
       &:nth-child(3) {${tw`fill-gray-500`}}
       &:nth-child(4) {${tw`fill-gray-600`}}
       &:nth-child(5) {${tw`fill-gray-700`}}
       &:nth-child(6) {${tw`fill-gray-800`}}
       &:nth-child(7) {${tw`fill-gray-900`}}
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = { active: null };
  }

  handleClick (slug) {
    navigate(`/approach/${slug}`);
  }

  handleMouseOut () {
    this.setState({ active: null });
  }

  handleMouseOver (id) {
    this.setState({ active: id });
  }

  render () {
    const {
      title,
      approachAreas,
      serviceActivities
    } = this.props;
    const { state } = this;
    logger.verbose(`this.props`, this.props)
    return (
      <ApproachCards active={state.active}>
        <Container fluid>
          <Row>
            {approachAreas && (
              approachAreas.length > 0 && approachAreas.map((edge, i) => {
                const { node } = edge;
                const inactive = !state.active ? false : `pasive`;
                const active = state.active === node.id ? `active` : inactive;
                const odd = i % 2 === 0;
                let mock = ``;
                if (i === 0) mock = `Understanding your customers to untap growth opportunities.`;
                if (i === 1) mock = `Refining your core for creating new value.`; // How innovatio impacts the growth of your business?
                if (i === 2) mock = `Leveraging your brands into valuable business assets.`;
                if (i === 3) mock = `Turning design into a continous innovation capability.`;
                const {
                  id,
                  title,
                  excerpt,
                  featuredImage,
                  slug
                } = node;
                const split = title.split(` `);
                let ordinal = ``;
                if (i === 0) ordinal = `01`;
                if (i === 1) ordinal = `02`;
                if (i === 2) ordinal = `03`;
                if (i === 3) ordinal = `04`;
                if (i === 4) ordinal = `05`;
                if (i === 5) ordinal = `06`;
                if (i === 6) ordinal = `07`;
                if (i === 7) ordinal = `08`;
                if (i === 8) ordinal = `09`;

                logger.verbose(`approachAreas node`, node);
                // logger.verbose(`approachAreas split`, split);
                return (
                  <ApproachArea key={id} to={`approach/${slug}`}>
                    <Row reverse={odd}>
                      <ApproachAreaImage>
                        <Thumbnail src={`https://images.ctfassets.net/yz9sknn7lxni/13HVR43AvaD4AOLY5Ep7lO/13f64d50150a688c0aec1050763665f1/placeholder.png`}>
                          {featuredImage && <BgImg {...featuredImage.thumbnail} />}
                        </Thumbnail>
                      </ApproachAreaImage>
                      <ApproachAreaContent reverse={odd}>
                        <SectionLabel ordinal={ordinal} label={`${title}`} />
                        <h1>{excerpt && excerpt.childMdx.excerpt}</h1>
                        {/*
                        <h1>
                          {split.map((w, i) => {
                            logger.debug(`split`, split, w)
                            // if (i !== split.length - 1) {
                              if (i === 0) {
                              return [
                                <span key={`${id}-${w}`}>
                                  {w}</span>,
                                <br /> 
                              ];
                            }
                            // if (i === split.length - 1) {
                              if (i !== 0) {
                                return [
                                  <span key={`${id}-${w}`}>
                                  {w}
                                  {split.length > 2 && <em> </em>}
                                </span>
                              ];
                            }
                          })}
                        </h1>
                         */}
                        <p>{excerpt && excerpt.childMdx.excerpt}</p>
                        <CTAMore>
                          Learn More
                          <span></span>
                        </CTAMore>
                      </ApproachAreaContent>
                    </Row>
                  </ApproachArea>
                );
              })
            )}
          </Row>
        </Container>
        {/*
        <SectionTitle>
          <Container>
            <Row>
              <div>
                <SectionLabel label={`Innovation Challenges`} />
                <h1>What’s your goal today?</h1>
                <p>
                  Floatless monocystic xanthopsydracia nicotinian allometric condone seeking forest miasmatic cornwallis Leptinotarsa acleidian
                </p>
              </div>
            </Row>
          </Container>
        </SectionTitle>
        */}
        {/*
        <CardsContainer>
          <Container fluid>
            <Row>
              <ColMenu>
                { approachAreas && (
                  approachAreas.length > 0 && approachAreas.map((edge, i) => {
                    const { node } = edge;
                    const inactive = !state.active ? false : `pasive`;
                    const active = state.active === node.id ? `active` : inactive;

                    let mock = ``;
                    if (i === 0) mock = `Understanding your customers`;
                    if (i === 1) mock = `Growth & Innovation`;
                    if (i === 2) mock = `How relevant is your brand?`;
                    if (i === 3) mock = `How design drives growth?`;

                    return (
                      <ApproachCard
                        key={`nav-${node.id}`}
                        i={i}
                        active={active}
                        to={`/approach/${node.slug}`}
                        onMouseOut={() => this.handleMouseOut()}
                        onBlur={() => this.handleMouseOut()}
                        onMouseOver={() => this.handleMouseOver(node.id)}
                        onFocus={() => this.handleMouseOver(node.id)}
                      >
                        <Title index={i}>
                          {mock}
                        </Title>
                      </ApproachCard>
                    );
                  })
                )}
              </ColMenu>
              <ColDescription>
                { approachAreas && (
                  approachAreas.length > 0 && approachAreas.map((edge, i) => {
                    const { node } = edge;
                    return (
                      <Description
                        key={`decription-${node.id}`}
                        active={state.active === node.id}
                        onClick={() => this.handleClick(node.slug)}
                        onMouseOut={() => this.handleMouseOut()}
                        onBlur={() => this.handleMouseOut()}
                        onMouseOver={() => this.handleMouseOver(node.id)}
                        onFocus={() => this.handleMouseOver(node.id)}
                      >
                        <SectionLabel label={node.title} />
                        <h2>
                          {node.heading && node.heading.childMdx.excerpt}
                        </h2>
                        <p>
                          Our groundbreaking strategies, products and services are based on unique methods for retrieving insights. Our Researchers work in close collaboration with extraordinary Designers and Business Innovators to unravel new opportunities. There are no ordinary days at Veryday. We meet your customers in their real lives. Sometimes this takes us in to their home, in order to gain novel insights.
                        </p>
                      </Description>
                    );
                  })
                )}
              </ColDescription>
            </Row>
          </Container>
        </CardsContainer>
        */}
        {/*
                <VennDiagram />
        */}
      </ApproachCards>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
