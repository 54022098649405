import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Color from 'color';
import colors from 'styles/Color';

const Hero = styled.div`
  ${tw`
    relative
    text-white
    mb-3
    h-3
    xl:h-screen-50
    px-1.5
    py-3
  `};
  ${(props) => {
    const { background } = props;
    const bgColor = props.bgColor ? props.bgColor : `bg-gray-800`;
    const backgroundColor = colors[bgColor];
    const backgroundColorParse = Color(backgroundColor);
    const isLight = backgroundColorParse.isLight();
    return [
      !isLight ? tw`text-white` : tw`text-black`,
      backgroundColor && `background-color: ${backgroundColor}`,
      background && (background.image && `background-image: url('${background.image}');`),
      background && (background.size ? `background-size: ${background.size};` : `background-size: cover;`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`)
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const { children } = this.props;
    return (
      <Hero {...this.props}>
        {children}
      </Hero>
    );
  }
}

Component.propTypes = {
  children: PropTypes.node
};

Component.defaultProps = {
  children: ``
};
