import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import logger from 'utils/logger';
import {
  SectionLabel
} from 'components/Text';

const BusinessFunction = styled.div`
  ${tw`
    font-light
    xl:text-lg
    xl:w-1/4
  `};
`;
const List = styled.ul`
  ${tw`
    mb-3
  `};
`;
const ListItem = styled.li`
  ${tw`
    mb-0.5
  `};
  &:before {
    ${tw`
      inline-block
      relative
      h-2px
      top--4px
      w-1.5
      bg-gray-200
      mr-1
    `};
    content: ""
  }
`;

export default class Component extends React.Component {
  render () {
    const {
      title,
      serviceActivities
    } = this.props;
    return (
      <BusinessFunction>
        <SectionLabel label={title} />
        {serviceActivities && (
          serviceActivities.length > 0 && (
            <List>
              {serviceActivities.map((node) => {
                return (
                  <ListItem key={node.id}>
                    {node.title}
                  </ListItem>
                );
              })}
            </List>
          )
        )}
      </BusinessFunction>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
