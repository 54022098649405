import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

const Text = styled.h2`
  ${tw`
    font-sans
    leading-normal
    py-1.5
    my-3
    tracking-tight
    font-light
    text-hero
  `};
  ${(props) => {
    return [
      props.align === `center` && tw`text-center`,
      props.border && tw`border-gray-200 border-solid border-0 border-b`
    ];
  }};
`;

export default class Component extends React.Component {
  render () {
    const {
      title
    } = this.props;
    return (
      <Text {...this.props}>
        {title}
      </Text>
    );
  }
}

Component.propTypes = {
  title: PropTypes.string
};
Component.defaultProps = {
  title: `Label`
};
