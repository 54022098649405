import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
// import logger from 'utils/logger';
import { Container, Row } from 'components/GridSystem';
import { SectionLabel } from 'components/Text';

const Expertise = styled.section`
  ${tw`bg-gray-200 pt-4 pb-6 lg:pr-1 xl:pr-1/12`};
`;

const ColText = styled.div`
  ${tw`
    lg:w-3/5
    lg:pr-3
  `};
`;

const ColImg = styled.div`
  ${tw`
    w-full
    md:w-1/2
    lg:w-1/3
    xl:w-1/4
    pr-2
    pt-2
  `};
  &:nth-child(4) {
    article {
      & > div {
        left: 1rem;
      }
    }
  }
`;

const CardCapability = styled.article`
  ${tw`
    relative
    bg-gray-800
    overflow-hidden
    text-white
    w-full
    block
    pt-aspect-ratio-square
  `};
  & > div {
    ${tw`absolute top-0 left-0 bottom-0 right-0 p-2`}
  }
  h2 {
    ${tw`text-h4 font-normal leading-tight`}
  }
`;

const animationDuration = 600;

export default class Component extends React.Component {
  render () {
    const { label, intro } = this.props;

    const capabilities = [
      {
        id: `capability-1`,
        title: `Brand Design Systems`
      },
      {
        id: `capability-2`,
        title: `Improve and evolve existing products.`
      },
      {
        id: `capability-3`,
        title: `Explore new markets, new technologies, or new business models and/or retool existing innovation efforts.`
      },
      {
        id: `capability-4`,
        title: `Img`
      },
      {
        id: `capability-5`,
        title: `Img`
      }
    ];

    return (
      <Expertise>
        <Container fluid align={`center`}>
          <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration / 2}>
            <SectionLabel label={label} />
          </Fade>
          <Row>
            {capabilities.map(({ id, title }, i) => {
              return (
                <ColImg key={`capability-${id}`}>
                  <Fade bottom duration={animationDuration + ((animationDuration / 10) * i)} distance={`10%`} delay={((animationDuration / 10) / 2) * i}>
                    <CardCapability>
                      <div>
                        <h2>{title}</h2>
                      </div>
                    </CardCapability>
                  </Fade>
                </ColImg>
              );
            })}
          </Row>
        </Container>
      </Expertise>
    );
  }
}
Component.propTypes = {
  intro: PropTypes.string,
  label: PropTypes.string
};
Component.defaultProps = {
  intro: 'Ballist no seband stere airedale rus ot barosmin codfis her butanolide geotical sul pharsenious arsenostyracol',
  label: 'Capabilities'
};
