import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import { JSONLD, Generic } from 'react-structured-data';
import {
  Container
} from 'components/GridSystem';

const Article = styled.article`
  ${tw`
    min-h-screen
    flex
    mb-0
    items-stretch
    top-0
    content-center flex-wrap
  `};
  ${(props) => {
    const { background, color } = props;
    return background && [
      `background-color: ${color || `transparent`};`,
      background.image && `background-image: url('${background.image}');`,
      background.size ? `background-size: ${background.size};` : `background-size: cover;`,
      background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`,
      background.position ? `background-position: ${background.position};` : `background-position: center;`
    ];
  }};
`;

const Content = styled.div`
  ${tw`
    flex-2 
    xxs:max-w-11/12
    xs:max-w-3/4
    sm:max-w-2/3
    md:max-w-1/2
    lg:max-w-1/3
    xl:max-w-1/4
  `};
  min-height: 430px;
`;

const Link = styled.div`
  ${tw`opacity-100 bg-transparent p-0 m-0 text-left cursor-pointer`};
  color: inherit;
  transition: all 600ms;
  &:focus,
  &:hover {
    ${tw`opacity-100`};
  }
`;

export default class Component extends React.Component {
  render () {
    const { background, children } = this.props;
    return (
      <Article background={background}>
        {/*
        <JSONLD>
          <Product name="Product Name">
            <AggregateRating ratingValue={4.3} reviewCount={197}/>
            <GenericCollection type="review">
              <Review name="It's awesome" reviewBody="This is Great! My family loves it" datePublished="11/22/1963">
                <Author name="Jerry"/>
                <Location name="Chicago, IL"/>
                <Rating ratingValue={5} />
              </Review>
              <Review name="Very cool" reviewBody="I like this a lot. Very cool product" datePublished="11/22/1963">
                <Author name="Cool Carl"/>
                <Location name="Chicago, IL"/>
                <Rating ratingValue={4} />
              </Review>
            </GenericCollection>
          </Product>
        </JSONLD>
        */}
        <Container>
          <Content>
            <Link>
              {children}
            </Link>
          </Content>
        </Container>
      </Article>
    );
  }
}

Component.propTypes = {
  background: PropTypes.shape({
    image: PropTypes.string,
    size: PropTypes.string,
    repeat: PropTypes.string,
    position: PropTypes.string
  }),
  children: PropTypes.node
};
Component.defaultProps = {
  background: {
    image: ``,
    size: ``,
    repeat: ``,
    position: ``
  },
  children: ``
};
