import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Color from 'color';
// import logger from 'utils/logger';
import Colors from 'styles/Color';
import { Container } from 'components/GridSystem';

const Intro = styled.section`
  ${tw`
    py-6
    relative
    mb-0
    flex
    items-start
  `};
  ${({ bgColor }) => {
    const bgColorProcessed = (bgColor.startsWith('#') || bgColor.startsWith('rgb') || bgColor.startsWith('hsl')) ? bgColor : Colors[bgColor];
    const color = Color(bgColorProcessed).isLight() ? `light` : `dark`;
    return [
      bgColor && `background-color: ${bgColorProcessed};`,
      color === `dark` ? tw`text-white` : tw`text-black`
    ];
  }};
  h1 {
    ${tw`
      font-thin
      font-serif
      tracking-tight
      lg:leading-hero-xl-loose
      lg:text-hero
      xl:max-w-5/6
      xl:text-hero-lg
    `};
  }
`;

export default class Component extends React.Component {
  render () {
    const {
      label,
      content,
      bgColor
    } = this.props;
    return (
      <Intro bgColor={bgColor}>
        <Container align={`center`}>
          <Fade
            bottom
            duration={600}
            distance={`2%`}
          >
            <h1>
              {content}
            </h1>
          </Fade>
        </Container>
      </Intro>
    );
  }
}
Component.propTypes = {
  content: PropTypes.string.isRequired,
  label: PropTypes.string,
  bgColor: PropTypes.string
};
Component.defaultProps = {
  label: 'Intro',
  bgColor: 'white'
};
